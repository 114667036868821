
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f5f6f8;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #5b73e8;
$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f46a6a;
$orange: #f1734f;
$yellow: #f1b44c;
$green: #34c38f;
$teal: #050505;
$cyan: #50a5f1;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
$purple: $purple;

.container-custom-badge {
  display: flex;

  .custom-badge {
      padding: 5px 10px;
      border-radius: 8px;
      font-size: 12px;
  }

  .badge-primary {
    background: $primary;
    color: $white;
  }

  .badge-secondary {
    background: $gray-500;
    color: #000;
  }

  .badge-success {
    background: $success;
    color: $white;
  }

  .badge-info {
    background: $cyan;
    color: $white;
  }

  .badge-danger {
    background: $danger;
    color: $white;
  }

  .badge-dark {
    background: $dark;
    color: $white;
  }
}
