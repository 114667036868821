.label-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: #ff0000;
    margin-left: 5px;
  }

  i {
    font-weight: 400;
  }
}

.AvCheckboxGroup {
  border: 1px solid #ced4da;
  padding: 0.47rem 0.75rem;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.AvCheckboxGroup label {
  font-weight: 400;
}

.AvCheckboxGroup .optionHelp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copy:active {
  transition: 0.1s;
  color: #14b538;
}

.textarea-whatsapp {
  display: flex;
  flex-direction: row;
  width: 100%;

  .tw-editor {
    flex: 1;
  }

  .tw-simulador {
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .tw-editor {
      margin-bottom: 20px;
    }
  }
}

input[type="color"] {
  border: 1px solid #d3d8de !important;
  padding: 0;
  height: 35px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
