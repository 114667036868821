.copy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  p {
    display: inline-block;
    width: calc(100% - 40px);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  i {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}

.buttons {
  Button {
    margin-left: 10px;
  }
}