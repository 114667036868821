.nav {
  border-bottom: 1px solid #ced4d9;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .navLink {
    cursor: pointer;
    padding: 10px 15px;

    &.active {
      background: #aaa;
      color: #fff;
      font-weight: bold;
      border-radius: 5px;
    }
  }
}
