.account-pages {
  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-login {
      min-width: 30vw;
      max-width: 500px;
      align-self: center;
    }
  }
}
