.avatar-container {
    position: relative;

    .codigo {
        position: absolute;
        top: -8px;
        right: -8px;

        background: #000000;
        color: #FFFFFF;
        padding: 5px;
        border-radius: 8px;
        font-size: 10px;
    }
}