.carregando {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .carregando-spinner {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 20px;
  }
}
