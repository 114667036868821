.copyright-container {
  .color-black {
    color: #000000;
  }

  .color-white {
    color: #ffffff;
  }

  img {
    margin-left: 5px;
    height: 30px;
  }
}
